<template>
  <div class="searchview">
    <div class="searchview__content">
      <div class="searchview__logo">
        <router-link to="/"><img src="/images/sc-logo.svg"></router-link>
      </div>
      <Searchbar />
      <CategoryFilter />
      <div class="searchview__loading"
           v-if="loading">
        <img src="/images/ajax-loader.gif"
             alt="Loading">
      </div>
      <div class="searchview__list"
           v-if="query && !loading">
        <h1>{{ count }} Resultate für "{{ query }}"</h1>
        <SheetPreviewList :sheets="sheets" />
      </div>
      <div class="searchview__ref"
           v-if="sheets.length > 0">
        <a target="_blank"
           href="https://www.algolia.com/"><img src="/images/algolia.svg"
               alt="Search by Algolia"></a>
      </div>
      <div class="searchview__footer">
        <span>SchulArena – ein Geschäftsbereich der ABALIR AG</span>
        <span>© {{ currentYear }} ABALIR AG</span>
        <ul>
          <li><a target="_blank"
               href="https://www.schularena.com/start/agb">Nutzungsbestimmungen</a></li>
          <li><a target="_blank"
               href="https://www.schularena.com/start/datenschutzerklaerung">Datenschutzerklärung</a></li>
          <li><a target="_blank"
               href="https://www.schularena.com/start/kontakt">Kontakt</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SheetPreviewList from './SheetPreviewList';
import Searchbar from './Searchbar';
import CategoryFilter from './CategoryFilter';

export default {
  name: 'searchview',
  metaInfo() {
    return {
      title: 'Suche'
    }
  },
  data() {
    return {
      sheetId: null,
      apiUrl: process.env.VUE_APP_APIURL,
      sheets: [],
      query: '',
      count: 0,
      loading: false
    }
  },
  watch: {
    '$route': function () {
      if (this.$route.query.q) {
        this.query = this.$route.query.q;
      }

      this.loadData();
    },
    activeCategoriesString(to, from) {
      this.loadData();
    }
  },
  components: {
    SheetPreviewList,
    Searchbar,
    CategoryFilter
  },
  mounted() {
    this.$store.commit('setHeader', {
      title: 'schularena',
      subtitle: 'Onlineübungen'
    });

    if (this.$route.query.q) {
      this.query = this.$route.query.q;
    }

    if (!this.query) return;
    this.loadData();
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    },
    activeCategoriesString() {
      return this.$store.getters.activeCategoriesString;
    }
  },
  methods: {
    loadData() {
      this.loading = true;
      let url = this.apiUrl + 'api/search?q=' + this.query;
      if (this.activeCategoriesString) {
        url += '&cats=' + this.activeCategoriesString;
      }
      axios.get(url)
        .then(data => {
          this.loading = false;
          this.sheets = data.data.result;
          this.count = data.data.count;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss">
@import '../styles/helpers';

.searchview {
  margin-top: -4rem;
  margin-bottom: -4rem;

  h1 {
    text-align: left;
  }

  &__logo {
    width: 100%;
    max-width: 430px;
    margin: 0 auto 3rem auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__loading {
    text-align: center;
  }

  &__ref {
    margin-top: 3rem;
    text-align: center;
    opacity: .7;

    img {
      width: 7rem;
      height: auto;
    }
  }

  &__footer {
    margin-top: 4rem;
    text-align: center;
    font-size: .8rem;
    color: #888;

    span {
      display: block;
      font-weight: 700;
      margin-bottom: .25rem;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline;

        a {
          text-decoration: none;
        }

        &::after {
          content: " | ";
          color: $c-red;
        }

        &:last-child::after {
          content: "";
        }
      }
    }
  }

  &__list {

    h1 {
      font-weight: normal;
      text-align: left;
      font-size: 1.5rem;
    }
  }
}

.c-index__content {
  @include routerTransition;
}</style>
